<template>
  <!-- *** LIST VIEW *** -->
  <EuiContainer>
    <confirmation-dialog v-if="itemToDelete"
       :title="$t('settings.users.delete.title')"
       :confirmText="$t('settings.users.delete.confirmText')"
       :cancelText="$t('settings.users.delete.cancelText')"
       :type="'danger'"
       @cancel="onCancel"
       @confirm="onConfirm"
    >
      <p v-html="$t('settings.users.delete.content', { 'user': sanitizedName })"/>
    </confirmation-dialog>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <div class="flex items-center">
            <EuiHeading :level="1" bold>{{ $t('settings.index.aside-menu.item.users') }}</EuiHeading>
            <div class="flex flex-1 justify-end">
              <EuiButton v-if="$store.state.ModuleEdocSign.auth.profile.features && $store.state.ModuleEdocSign.auth.profile.features.hasEditUserPermission === '1'" variant="raised" color="primary" @click="$router.push({ name: 'settings.users.create' })">
                {{ $t('button.add') }}
              </EuiButton>
            </div>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('settings.users.title')">
            <EuiGrid>
              <EuiGridRow>
                <EuiGridColumn>
                </EuiGridColumn>
                <EuiGridColumn>
                  <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                            v-model.trim="searchInput"
                            @keyup.enter="searchUser()">
                    <template #endEnhancer>
                      <div class="eui-m-cardFilter__clear">
                        <template v-if="searchInput && searchInput.length > 0">
                          <EuiButton iconOnly size="small" color="primary" @click.stop="onClear">
                            <EuiIcon name="close"/>
                          </EuiButton>
                          <div class="eui-m-cardFilter__separator"></div>
                        </template>
                      </div>
                      <EuiButton iconOnly color="primary" size="small" @click.stop="searchUser()">
                        <EuiIcon name="search"/>
                      </EuiButton>
                    </template>
                  </EuiInput>
                </EuiGridColumn>
              </EuiGridRow>
            </EuiGrid>
            <EuiAdvancedTable v-if="users.length > 0">
              <template #tableHead>
                <EuiTableHead @onSortClick="onSortClick($event, 'fullname')">
                  <div class="eui-u-flex">{{ $t('settings.users.table.name') }}</div>
                </EuiTableHead>
                <EuiTableHead @onSortClick="onSortClick($event, 'email')">
                  <div class="eui-u-flex">{{ $t('settings.users.table.email') }}</div>
                </EuiTableHead>
                <EuiTableHead>
                  <div class="eui-u-flex">{{ $t('settings.users.table.group') }}</div>
                </EuiTableHead>
                <EuiTableHead />
              </template>
                <!-- table row -->
                <EuiTableRow v-for="item in users" :key="item.userId" @click.native="goToEditPage(item.userId)">
                  <!--
                  <EuiTableCell dataType="input">
                    <EuiCheckbox :value="item" v-model="selectedUsers"/>
                  </EuiTableCell>
                  -->
                  <!-- full name -->
                  <EuiTableCell>{{ item.fullname }}</EuiTableCell>
                  <EuiTableCell>{{ item.email }}</EuiTableCell>
                  <EuiTableCell>{{ item.group }}</EuiTableCell>
                  <EuiTableCell dataType="button">
                    <EuiDropdownMenu v-if="$store.state.ModuleEdocSign.auth.profile.features && $store.state.ModuleEdocSign.auth.profile.features.hasEditUserPermission === '1'" >
                    <template v-slot:anchor>
                      <EuiButton iconOnly color="primary" size="small" @click.stop>
                        <EuiIcon name="more_vert"/>
                      </EuiButton>
                    </template>
                    <EuiList>
                      <EuiListItem :button="true" @click="goToEditPage(item.userId)">
                        {{ $t('button.edit') }}
                      </EuiListItem>
                      <EuiListItem :button="true" @click.stop="openDeleteConfirmationDialog(item)">
                        {{ $t('button.delete') }}
                      </EuiListItem>
                    </EuiList>
                    </EuiDropdownMenu>
                    <EuiButton v-else variant="text" color="primary" @click.stop="goToEditPage(item.userId)">Voir</EuiButton>
                  </EuiTableCell>
                </EuiTableRow>
            </EuiAdvancedTable>
            <EuiPagination v-if="users.length"
                           class="mt-4"
                           :value="pagination.current"
                           @change="goToPage"
                           :paginationText="$t('pagination.text')"
                           :totalPages="pagination.total"/>
            <div v-if="users.length === 0">
              <p>{{ $t('signatories.social.index.noSignatories') }}</p>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DOMpurify from 'dompurify';

export default {
  /**
   * The name of the page.
   */
  name: 'SettingsUsersIndex',
  components: {
    confirmationDialog: () => import(/* webpackPrefetch: true */ '@/pages/settings/dialog/confirmation'),
  },
  /**
   * The data the page can use.
   *
   * @returns {Object} The data.
   */
  data() {
    return {
      users: [],
      selectedUsers: [],
      searchParams: {
        orders: [{ fieldName: 'fullname', orderBy: 'ASC' }],
        params: [],
        offset: 0,
        limit: 20,
      },
      searchInput: '',
      itemToDelete: null,
    };
  },
  /**
   * The computed properties the page can use.
   */
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.settingsUsers.pagination,
    }),
    sanitizedName() {
      return DOMpurify.sanitize(this.itemToDelete.fullname);
    },
    selectAll: {
      get() {
        return this.users ? this.selectedUsers.length === this.users.length : false;
      },
      set(value) {
        const selectedUsers = [];

        if (value) {
          this.users.forEach((user) => {
            selectedUsers.push(user);
          });
        }
        this.selectedUsers = selectedUsers;
      },
    },
  },

  /**
   * The methods the page can use.
   */
  methods: {
    ...mapActions({
      search: 'ModuleEdocSign/settingsUsers/search',
      searchGroup: 'ModuleEdocSign/settingsGroups/search',
      destroy: 'ModuleEdocSign/settingsUsers/destroy',
    }),
    onSortClick(orderBy, fieldName) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.searchUser();
    },
    onClear() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.searchInput = '';
      this.searchUser();
    },
    openDeleteConfirmationDialog(item) {
      this.itemToDelete = item;
    },
    onCancel() {
      this.itemToDelete = null;
    },
    onConfirm() {
      this.destroy(this.itemToDelete.userId).then(() => {
        this.onCancel();
        this.searchUser();
      });
    },
    goToEditPage(userId) {
      this.$router.push({ name: 'settings.users.edit', params: { userId } });
    },
    deleteSelected() {
      const promises = [];
      for (let i = 0; i < this.selectedUsers.length; i++) {
        promises.push(this.destroy({ userId: this.selectedUsers[i].userId }));
      }
      Promise.all(promises)
        .then(() => {
          this.searchUser(this.searchParams);
        });
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      const users = this.search(this.searchParams).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.searchGroup({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 20 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      Promise.all([users, groups]).then((values) => {
        for (let i = 0; i < values[0].length; i += 1) {
          const group = values[1].find((element) => element.id === values[0][i].userGroupId);
          if (group) {
            values[0][i].group = group.name;
          } else {
            // values[0][i].group = '';
          }
        }
        this.users = values[0];
      });
    },
    searchUser() {
      this.searchParams.offset = 0;
      if (this.searchInput) {
        this.searchParams.params = [{
          paramName: 'token', paramValue: this.searchInput,
        }];
      } else {
        this.searchParams.params = [];
      }
      const users = this.search(this.searchParams).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.searchGroup({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 20 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      Promise.all([users, groups]).then((values) => {
        for (let i = 0; i < values[0].length; i += 1) {
          const group = values[1].find((element) => element.id === values[0][i].userGroupId);
          if (group) {
            values[0][i].group = group.name;
          } else {
            // values[0][i].group = '';
          }
        }
        this.users = values[0];
      });
    },
  },
  /**
   * This method will be fired once the application has been mounted.
   */
  mounted() {
    this.searchUser();
  },
};
</script>
